import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { User } from './user';
 
import { CommonserviceService } from '../service/commonservice.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public loggedIn = new BehaviorSubject<Object>(JSON.parse(sessionStorage.getItem("user")));

  constructor(private _http: HttpClient, public router:Router, public _service:CommonserviceService) { }


  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }
  login(user: User) {
    var self = this;
    if (user.email !== '' && user.password != '') {
      var sendRequestData = user;
       this._service.loadingShow = true;
      self._service.post('admin_login', sendRequestData).subscribe((res: any) => {
        // console.log(res);

         self._service.loadingShow = false;
        // console.log("res check", res);
        if (res.replyCode == "success") {


          self._service.showSuccess(res.replyMsg);
          // self.toastr.success(res.replyMsg, 'Success!');
          if (res.data.isMobileVerified == 0) {
            sessionStorage.setItem("usertemp", JSON.stringify(res.data));
            self.router.navigate(['/forgot/1']);
          } else {


            res.data.role = user.role;
            sessionStorage.setItem("user", JSON.stringify(res.data));
            // console.log( sessionStorage.setItem("user", JSON.stringify(res.data)));

            self.loggedIn.next(res.data);
            if (user.role == "cinemacashier") {
              self.router.navigate(['/' + user.role + "/0"]);
            }
            else {
              // self.router.navigate(['/' + user.role]);
              //self._service.showError(res.replyMsg);
              self.router.navigate(['/dashboard']);
            }
          }

        }
        else {
          self._service.showError(res.replyMsg);

          // self.toastr.error(res.replyMsg, 'Error!');
        }
      }
        , error => {
           self._service.loadingShow = false;
          self._service.showError("Oops! Something went wrong!");

          // self.toastr.error('Oops! Something went wrong!', 'Error!');
          
          // self._service.showError("Oops! Something went wrong!");
        });

    }
  }

  logout() {
    /* setTimeout(function(){
      
    
      
    },1000);
     */
    //window.location.reload();
    this.loggedIn.next(null);
    sessionStorage.removeItem("user");
    sessionStorage.removeItem('usertemp');
    sessionStorage.removeItem('language');
    sessionStorage.removeItem('lang');
    this.router.navigate(['/']);


    // window.location.href="http://demoapp.pocketbank.in/";
  }
}


import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { Observable } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { CommonserviceService } from './service/commonservice.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Learning Bix';

  isLoggedIn$: Observable<object>;
  constructor(private authService: AuthService, private router: Router, public  httpClient: CommonserviceService, 
    private mdbSpinningPreloader: MDBSpinningPreloader,private cdr: ChangeDetectorRef,private datePipe: DatePipe) {
  	
  	// this.userData = JSON.parse(sessionStorage.getItem("user"));
  	//console.log("userData", this.userData);

  }

  ngOnInit() {
    var date = new Date();
    console.log('date:::::',date);
    console.log('dfgdf',this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss'))
    this.mdbSpinningPreloader.stop();
    this.isLoggedIn$ = this.authService.isLoggedIn;
    console.log("this.isLoggedIn$", this.isLoggedIn$)
    //this.getData();
  }
ngAfterViewChecked(){
   //your code to update the model
   this.cdr.detectChanges();
}
}

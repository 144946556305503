import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ToastService } from 'ng-uikit-pro-standard';
import { environment } from 'src/environments/environment';
import { EnvService } from './env.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonserviceService {
  public baseApiUrl: any;
  public imageBaseApiUrl: any;
  public auth_token: any;
  isContentHidden = new BehaviorSubject<boolean>(false);
  isLogoutPopup = new BehaviorSubject<boolean>(false);
  public loadingShow: boolean = false;
  constructor(public _http: HttpClient, private toastrService: ToastService, private envService: EnvService) {
    // this.baseApiUrl='https://api.learningbix.com:8113/' /* Dev Url */
    /* Live Url */
    // this.imageBaseApiUrl='https://api.learningbix.com/uploads/'
    this.imageBaseApiUrl = environment.imageBase_api_url;
    this.baseApiUrl = environment.api_url;

  }


  //   get(url) {

  //     let headers = new HttpHeaders({'Authkey': this.auth_token,'Content-Type':'application/json'});
  //     //this.createAuthorizationHeader(headers);
  //     return this._http.get(this.baseApiUrl+url, {
  //       headers: headers

  //   });
  // }
  get(url) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //this.createAuthorizationHeader(headers);
    return this._http.get(this.baseApiUrl + url, {
      headers: headers
    });
  }
  post(url, data) {
    //  if(this.auth_token){
    //   let headers = new HttpHeaders({'Authkey': this.auth_token,'Content-Type':'application/json'});
    //   return this.http.post(this.serviceBase+url, data, {
    //     headers: headers
    //   });
    //  }else{
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(this.baseApiUrl + url, data, {
      headers: headers
    });
  }
  GetLogoutPopup(){return this.isLogoutPopup.asObservable()}
    SetLogoutPopup(data: boolean){this.isLogoutPopup.next(data)}
    GetContentHidden(){return this.isContentHidden.asObservable()}
    SetContentHidden(data: boolean){this.isContentHidden.next(data)}
  postFile(fileToUpload: File) {

    const formData: FormData = new FormData();
    formData.append('attachment', fileToUpload, fileToUpload.name);
    return this._http.post(this.baseApiUrl + 'upload_file', formData)
  }
  downloadCSV(data, fileName) {
    var csvData = this.ConvertToCSV(data);
    var a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    var blob = new Blob([csvData], { type: 'text/csv' });
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
  }
  ConvertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = "";

    for (var index in objArray[0]) {
      //Now convert each value to string and comma-separated
      row += index + ',';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + '\r\n';

    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line != '') line += ','
        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }
  showSuccess(message) {
    this.toastrService.success(message);
  }

  showError(message) {
    this.toastrService.error(message);
  }

  showInfo(message) {
    this.toastrService.info(message);
  }

  showWarning(message) {
    this.toastrService.warning(message);
  }
  getClass(): Observable<any> {
    return this._http.get("../../assets/class.json");
  }
}

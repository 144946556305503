<section class="innerpage-section">
  <ol class="breadcrumb">
    <!-- <a href="javascript:void(0)" (click)="gotoBack()" class="back_btn"><i class="fas fa-arrow-left"></i> Back</a> -->
    <li class="breadcrumb-item active" aria-current="page">In-person class Enquiry List</li>

  </ol>
  <!-- <div class="row page-header">
    <div>
      <p class="text-title">Manage Role</p>
    </div>

  </div> -->
  <div class="row my-3">
    <div class="col-6">
      <!-- <button type="button" class="btn custom-btn mr-2" data-toggle="modal" data-target="#myModal">Add New Client</button> -->
      <!-- <button  mdbBtn type="button" gradient="purple" size="sm" mdbWavesEffect (click)="addNewRecord()" >Add New</button> -->
      <!-- <button mdbBtn type="button" gradient="purple" size="sm" mdbWavesEffect>Small Button</button>  -->
      <!-- <button type="button" class="btn custom-btn ml-2">Download Report</button> -->
    </div>
    <div class="col-6 search-section">
      <!-- <i class="fa fa-filter filter-icon" aria-hidden="true"></i>
      <select class="filter-select mr-2">
        <option selected>Filter</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
      </select> -->

      <form class="form-inline" style="
      text-align: right;
      float: right; 
    ">
        <!-- <input class="form-control my-0 py-0" formControlName="keyword" placeholder="Enter a keyword"
          type="text"> -->
        <input type="text" mdbInput class="form-control ml-2" name="keyValue" [(ngModel)]="keyValue"
          aria-describedby="SerchHelp" placeholder="Search" (keyup)="onSearch()">

        <!-- <button type="button" class="btn btn-sm btn-primary ml-2 px-2 waves-effect waves-light" (click)="onSearch()"><i
            class="fa fa-search"></i></button>
        <button type="button" class="btn btn-sm btn-primary ml-2 px-2 waves-effect waves-light" (click)="onExport()"><i
            class="fas fa-file-download"></i>&nbsp;Export</button> -->
      </form>
    </div>

  </div>
  <div class="col-12">
    <div class="row">
      <div class="col-6">
        <label>
          Show
          <select class="filter-select mr-2" [(ngModel)]="bodyDataRequest['limit']"
            (change)="onChangeLimit($event.target.value)">
            <option selected disabled>select</option>
            <option value="3">3</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          Records
        </label>
      </div>
      <div class="col-6 text-right">
        <pagination-controls (pageChange)="p = $event" directionLinks="true" autoHide="false" previousLabel="Previous"
          nextLabel="Next" (pageChange)="onPageChange($event)"></pagination-controls>
      </div>
    </div>
  </div>
  <div class="row dash-layout">
    <div class="col-sm-12">
      <div class="table-responsive table-wrapper">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Name <i class="fas fa-sort"></i></th>
              <th>Father's name <i class="fas fa-sort"></i></th>
              <th>Contact <i class="fas fa-sort"></i></th>
              <th>Email <i class="fas fa-sort"></i></th>
              <th>Subject <i class="fas fa-sort"></i></th>
              <th>Additional details <i class="fas fa-sort"></i></th>
              <th>Created <i class="fas fa-sort"></i></th>
              <!-- <th>Status <i class="fas fa-sort"></i></th> -->
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let user of userlistData| paginate:{itemsPerPage:bodyDataRequest['limit'],currentPage:p,totalItems:total_count};let i= index">
              <td>{{ (p - 1) * bodyDataRequest['limit'] + i +1 }}</td>
              <td>{{user.first_name}} </td>
              <td>{{user.last_name}} </td>
              <td>{{user.contact}} </td>
              <td>{{user.email}} </td>
              <td>{{user.subject}} </td>
              <td>{{user.additional_details}} </td>
              <td>{{user.created | date}}</td>
              <!-- <td>
                <button class="btn btn-sm" [ngClass]="{
                  'btn-success':user.status == 1,
                  'btn-danger':user.status == 0
                }" (click)="updateStatusData(user)">{{user.status == 1?'Active':'Inactive'}}</button>
              </td> -->


              <td>
                <div class="d-flex">
                  <!-- <a href="javascript:void(0)" class="edit-btn px-1" (click)="viewCourse(user)">
                <mdb-icon far icon="eye"></mdb-icon>
              </a> -->
                  <!-- <a href="javascript:void(0)" class="edit-btn px-1" (click)="gotoEdit(user)">
                <i class="fas fa-edit" aria-hidden="true"></i>
              </a> -->
                  <a href="javascript:void(0)" class="delete-btn-icon px-1" (click)="gotoDelete(user)">
                    <i class="fas fa-trash" aria-hidden="true"></i>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- Modal -->

</section>
<div mdbModal #deleteModal="mdb-modal" class="modal fade" id="centralModalSuccess" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" style="overflow: auto;">
  <div class="modal-dialog modal-notify modal-danger" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="heading lead">Delete</p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="deleteModal.hide()">
          <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <i class="fa fa-close fa-4x mb-3 animated rotateIn"></i>
          <p>Are you sure, You want to delete this record?</p>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <a type="button" class="btn btn-primary-modal waves-light" mdbRippleRadius (click)="deleteRecord()">Yes</a>
        <a type="button" class="btn btn-outline-secondary-modal" data-dismiss="modal" (click)="deleteModal.hide()"
          mdbRippleRadius>No</a>
      </div>
    </div>
  </div>
</div>
<div mdbModal #updateStatusModal="mdb-modal" class="modal fade" id="centralModalSuccess" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" style="overflow: auto;">
  <div class="modal-dialog modal-notify modal-danger" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="heading lead">Update Status</p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="updateStatusModal.hide()">
          <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <i class="fa fa-close fa-4x mb-3 animated rotateIn"></i>
          <p>Are you sure, You want to change the status of this record?</p>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <a type="button" class="btn btn-primary-modal waves-light" mdbRippleRadius (click)="updateStatus()">Yes</a>
        <a type="button" class="btn btn-outline-secondary-modal" data-dismiss="modal" (click)="updateStatusModal.hide()"
          mdbRippleRadius>No</a>
      </div>
    </div>
  </div>
</div>


<div mdbModal #imagemodal="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <!--Carousel Wrapper-->
            <img class="img-fluid" src="{{this._employeeService.imageBaseApiUrl}}{{popImageUrl}}"
              onError="this.src='assets/images/no-image.png'">
            <!--/.Carousel Wrapper-->
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
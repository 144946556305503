import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonserviceService } from 'src/app/service/commonservice.service';
import { ExcelService } from 'src/app/service/excel.service';

declare const $: any;
@Component({
  selector: 'app-enquiries_list',
  templateUrl: './enquiries_list.component.html',
  styleUrls: ['./enquiries_list.component.scss']
})
export class EnquirylistComponent implements OnInit {
  @ViewChild('deleteModal', { static: false }) public deleteModal;
  @ViewChild('updateStatusModal', { static: false }) public updateStatusModal;
  @ViewChild('imagemodal', { static: false }) public imagemodal;
  userlistData: any;
  userId: any;
  user: any;
  keyValue = '';
  updateListData: any;
  popImageUrl = '';
  courseId: any;
  p: number = 1;
  total_count: any;
  bodyDataRequest: any = {};
  constructor(private router: Router, public _employeeService: CommonserviceService, private activatedRoute: ActivatedRoute, public excelService: ExcelService) {
    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.courseId = this.activatedRoute.snapshot.paramMap.get("othercourseId");
    console.log('courseId:::', this.courseId)
    // if (this.flag) {
    //   this.courseId = this.flag;
    // }
  }
  gotoBack() {
    this.router.navigate(['/course'])
  }
  ngOnInit(): void {
    this.bodyDataRequest['start'] = "0";
    this.bodyDataRequest['limit'] = "20";
    this.bodyDataRequest['learning'] = "0";
    this.bodyDataRequest['keyword'] = this.keyValue;
    this.getDataList(this.bodyDataRequest);

  }
  getDataList(bodyDataRequest) {
    var self = this;
    // var sendRequestData = undefined;
    var sendRequestData = {
      keyword: this.keyValue,
      learning:'0'
      // course_id: this.courseId
    };
    self._employeeService.loadingShow = true;
    self._employeeService.post('enquiries_list', this.bodyDataRequest).subscribe((res: any) => {
      self._employeeService.loadingShow = false;
      if (res.replyCode == 'success') {
        self.userlistData = res.data;
        this.total_count=res.totalCount;
      }
    },
      error => {
        self._employeeService.loadingShow = false;
        console.log("Oops! Something went wrong!");
      });
  }
  onExport() {
    var self = this;
    var sendRequestData = {
      keyword: '',
      learning: '0'
    };
    self._employeeService.loadingShow = true;
    self._employeeService.post('enquiries_list', sendRequestData).subscribe((res: any) => {
      self._employeeService.loadingShow = false;
      if (res.replyCode == 'success') {
        self.userlistData = res.data;
        if (self.userlistData.length > 0) {
          this.excelService.exportAsExcelFile(this.userlistData, 'Enquiry_data');
        }
      }
    }, error => {
      self._employeeService.loadingShow = false;
      console.log("Oops! Something went wrong!");
    });
  }
  resetfilter() {
    this.bodyDataRequest['start'] = "0";
    this.bodyDataRequest['keyword'] = '';
    this.getDataList(this.bodyDataRequest);
  }
  onChangeLimit(limit){
    this.p = 1;
    this.bodyDataRequest['start'] = "0";
    this.bodyDataRequest['limit'] = String(limit);
    this.getDataList(this.bodyDataRequest);
  }
  onSearch() {
    this.p = 1;
    this.bodyDataRequest['start'] = "0";
    this.bodyDataRequest['limit'] = "20";
    this.bodyDataRequest['keyword'] = this.keyValue;
    this.getDataList(this.bodyDataRequest);
  }
  onPageChange(event) {
    this._employeeService.loadingShow = true;
    this.p = event;
    // console.log('event::::', event);
    this.bodyDataRequest['start'] = String((event * this.bodyDataRequest['limit']) - this.bodyDataRequest['limit']);
    this.getDataList(this.bodyDataRequest);
  }
  openImage(item) {
    var self = this;
    self.popImageUrl = item;
    this.imagemodal.show();
  }
  updateStatusData(value) {
    this.updateListData = value
    this.updateStatusModal.show();
  }
  updateStatus() {
    var self = this;
    var value = self.updateListData;
    var status;
    this._employeeService.loadingShow = true;

    console.log(value);
    if (value.status == 1) {
      status = '0';
    }
    else {
      status = '1';

    }
    var sendRequestData = { "id": value.id, "status": status }
    console.log(sendRequestData);
    self._employeeService.loadingShow = true;
    self._employeeService.post('update_course_info_status', sendRequestData).subscribe((res: any) => {
      self._employeeService.loadingShow = false;
      if (res.replyCode == 'success'){
        this.updateStatusModal.hide();
        //self.employeeData= res.data;
        console.log(res);
        this.getDataList(this.bodyDataRequest);
      }
    },
      error => {
        self._employeeService.loadingShow = false;

        console.log("Oops! Something went wrong!");
      });

  }
  gotoDelete(value) {
    this.deleteModal.show();
    this.userId = value.id

  }
  deleteRecord() {
    var self = this;
    var sendRequestData = { "id": self.userId, "status": 2 }
    console.log(sendRequestData);
    self._employeeService.loadingShow = true;

    self._employeeService.post('delete_enquiries', sendRequestData).subscribe((res: any) => {
      self._employeeService.loadingShow = false;

      if (res.replyCode == 'success') {
        //self.employeeData= res.data;
        this.deleteModal.hide();
        console.log(res);
        this.getDataList(this.bodyDataRequest);

      }
    },
      error => {
        self._employeeService.loadingShow = false;

        console.log("Oops! Something went wrong!");
      });

  }

}

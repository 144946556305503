import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { CommonserviceService } from '../service/commonservice.service';
import { DomSanitizer } from '@angular/platform-browser';
declare const $:any;

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
userDetails:any;

  constructor(private router:Router, public authService: AuthService,public _employeeService: CommonserviceService,public sanitizer: DomSanitizer) { 
    this.userDetails= JSON.parse(sessionStorage.getItem('user'))
  }

  ngOnInit(): void {
    $(".sidebar-dropdown > a").click(function() {
      $(".sidebar-submenu").slideUp(250);
      if (
        $(this)
          .parent()
          .hasClass("active")
      ) {
        $(".sidebar-dropdown").removeClass("active");
        $(this)
          .parent()
          .removeClass("active");
      } else {
        $(".sidebar-dropdown").removeClass("active");
        $(".sidebar-dropdown").removeClass("active");
        $(this)
          .next(".sidebar-submenu")
          .slideDown(250);
        $(this)
          .parent()
          .addClass("active");
      }
    });
    $(".sidebar > a").click(function() {
      $(".sidebar-submenu").slideUp(250);
      if (
        $(this)
          .parent()
          .hasClass("active")
      ) {
        $(".sidebar-dropdown").removeClass("active");
        $(this)
          .parent()
          .removeClass("active");
      } else {
        $(".sidebar-dropdown").removeClass("active");
        $(".sidebar-dropdown").removeClass("active");
        $(this)
          .next(".sidebar-submenu")
          .slideDown(250);
        $(this)
          .parent()
          .addClass("active");
      }
    });
    $("#toggle-sidebar").click(function() {
      $(".page-wrapper").toggleClass("toggled");
    });
   }
   allowurl(url:any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
   changePassword(){
    this.router.navigate(['/shared/change-password'])
   }
   onLogout() {
    if(window.confirm('Are you sure you wish log out?')){
      this.authService.logout();
     }
  }
  profileView(){
    this.router.navigate(['/profile'])
  }
}

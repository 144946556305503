

<div class="full-screan-loadingbar" *ngIf="httpClient.loadingShow">
    <div class="data">
    <mdb-spinner spinnerType="big crazy"></mdb-spinner>
</div>
</div>

<!-- 
<mdb-progressbar class="loadingbar" [value]="httpClient.progressLoadingValue" min="0" max="100" aria-valuenow="0" aria-valuemin="0"
    aria-valuemax="100" *ngIf="httpClient.progressLoadingShow"></mdb-progressbar> -->

<router-outlet></router-outlet>
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export enum Environment {
  Prod = 'http://localhost:4200',
  Staging = 'staging',
  Test = 'http://localhost:4200',
  Dev = 'http://localhost:4200',
  Local = 'http://localhost:4200',
  Host = 'http://localhost:4200/',
}

@Injectable({ providedIn: 'root' })
  
export class EnvService {
  private _env: Environment;
  private _apiUrl: string;

  get env(): Environment {
    return this._env;
  }

  get apiUrl(): string {
    return this._apiUrl;
  }

  constructor() {}

  init(): Promise<void> {
    return new Promise(resolve => {
      this.setEnvVariables();
      resolve();
    });
  }

  private setEnvVariables(): void {
    const hostname = window && window.location && window.location.hostname;
    console.log('hostname::::::',hostname);
    if (/^.*localhost.*/.test(hostname)) {
      this._env = Environment.Local;
      this._apiUrl = `${environment.api_url}`;
      console.log('_env::::::',this._env);
      console.log('_apiUrl::::::',this._apiUrl);
    } else if (/^admin.learningbix.com/.test(hostname)) {
      this._env = Environment.Dev;
      this._apiUrl =  `${environment.api_url}`;
      console.log('_env::::::',this._env);
      console.log('_apiUrl::::::',this._apiUrl);
    } else if (/^admin-dev.learningbix.com/.test(hostname)) {
      this._env = Environment.Test;
      this._apiUrl =  `${environment.api_url}`;
      console.log('_env::::::',this._env);
      console.log('_apiUrl::::::',this._apiUrl);
    } else if (/^staging-app.mokkapps.de/.test(hostname)) {
      this._env = Environment.Staging;
      this._apiUrl =  `${environment.api_url}`;
      console.log('_env::::::',this._env);
      console.log('_apiUrl::::::',this._apiUrl);
    } else if (/^prod-app.mokkapps.de/.test(hostname)) {
      this._env = Environment.Prod;
      this._apiUrl =  `${environment.api_url}`;
      console.log('_env::::::',this._env);
      console.log('_apiUrl::::::',this._apiUrl);
    }else if (/^192.168.0.118/.test(hostname)) {
      this._env = Environment.Host;
      this._apiUrl =  `${environment.api_url}`;
      console.log('_env::::::',this._env);
      console.log('_apiUrl::::::',this._apiUrl);
    } else {
      console.warn(`Cannot find environment for host name ${hostname}`);
    }
  }
}
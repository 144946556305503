import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { map, take } from "rxjs/operators";
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private activatedRoute: ActivatedRoute) {
    router.events
    .subscribe()
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isLoggedIn
      .pipe(take(1),
        map((isLoggedIn: object) => {
          var self = this;
          setTimeout(function () {
            var url = window.location.pathname;
            if (!isLoggedIn && url != '/login') {
              self.router.navigate(['']);
              return false;
            }
            //console.log("url", url);
            /* var userData = JSON.parse(sessionStorage.getItem("user"));
             if (url == "/" || url == "/forgot" || url == "/forgot/cinemauser" || url == "/forgot/cinemacashier" || url == "/forgot/cinemakiosks" || url == "/cinemakiosks/login" || url == "/admin/login" || url == "/cinemauser/login" || url == "/cinemacashier/login" || url == "/cinemakiosks/login") {
               
               
             }
             else {
               if (!isLoggedIn){
                 self.router.navigate(['']);
                 return false;
               }
               
             }*/
          }, 10);

          return true;
        }));
  }
}
@Injectable({
  providedIn: 'root'
})
export class routePermissionGuard implements CanActivate {
  public title: any;
  userData: any;
  constructor(
    private router: Router) {
    this.userData = JSON.parse(sessionStorage.getItem('user'))
  }
  canActivate(): boolean {
    if (this.userData.role_id == 1) {
      return true
    }
    else {
      this.router.navigate(['dashboard'])
      return false
    }
  }
}

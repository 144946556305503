<div class="main-row">
  <div class="page-wrapper toggled">
    <div class="top-bar">
      <div class="col-12">
        <div class="row">
          <div class="col-6">
            <a href="javascript:void(0)" id="toggle-sidebar">
              <i class="fa fa-bars"></i>
              <!-- <i class="fa fa-chevron-double-left"></i> -->
              <!-- <i class="fas fa-chevron-left"></i> -->
            </a>
          </div>
          <div class="col-6 text-right">
            <p></p>
            <!-- <button mdbBtn type="button" gradient="purple" size="sm" mdbWavesEffect (click)="changePassword()">Change
                    Password</button> -->
          </div>
        </div>
      </div>
    </div>
    <nav id="sidebar" class="sidebar-wrapper">
      <div class="sidebar-content">
        <div class="sidebar-brand">
          <a href="javascript:void(0)">
                   <!-- <img src="assets/images/logo.png" alt="" class="img-fluid" /> -->
                   <p style="margin-bottom: -4px;
                   font-size: 33px;
                   letter-spacing: 0px;
                   word-spacing: 0px;
                   color: #cab776;
                   font-weight: 700;
                   text-decoration: none solid rgb(68, 68, 68);
                   font-style: normal;
                   font-variant: small-caps;
                   text-transform: capitalize;">Skolbryggan</p>
          </a>
        </div>
        <div class="sidebar-header" (click)="profileView()">
          <div class="user-pic">
            <img src="{{this._employeeService.imageBaseApiUrl}}{{userDetails.image}}"
                  onError="this.src='assets/images/users-default.jpg'" class="img-fluid"
                  style="width: 100px;">
          </div>
          <div class="user-info">
            <span class="user-name">
              <strong>{{ userDetails?.name }}</strong></span
            >
            <!-- <span class="user-role">{{userDetails?.role_access.role_title}}</span> -->
            <div class="user-status">
              <a href="javascript:void(0)">
                <i class="fa fa-circle"></i>
                <span>Online</span></a
              >
            </div>
          </div>
        </div>
        <!-- sidebar-header  -->
      <!--  <div class="sidebar-search">
              <div>
                <div class="input-group">
                  <input type="text" class="form-control search-menu" placeholder="Search for...">
                  <span class="input-group-addon"><i class="fa fa-search"></i></span>
                </div>
              </div>
            </div> -->
        <!-- sidebar-search  -->
        <div class="sidebar-menu">
          <ul>
            <li class="header-menu"><span>General</span></li>
            <li class="sidebar">
              <a
                href="javascript:void(0)"
                routerLink="/dashboard"
                routerLinkActive="active-link"
              >
                <i class="fas fa-th-large"></i>
                <span>Dashboard</span></a
              >
            </li>
            <!-- <li class="sidebar">
              <a
              href="javascript:void(0)"
              routerLink="/admin/employee"
              routerLinkActive="active-link"
              >Sub Admin
              </a>
            </li> -->
            <li class="sidebar-dropdown">
              <a href="javascript:void(0)"
                ><i class="fas fa-user-alt"></i><span>Users</span></a
              >
              <div class="sidebar-submenu">
                <ul>
                  
                  <li>
                    <a
                      href="javascript:void(0)"
                      routerLink="/admin/parent"
                      routerLinkActive="active-link"
                      >Parents</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      routerLink="/admin/student"
                      routerLinkActive="active-link"
                      >Students</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      routerLink="/admin/teacher"
                      routerLinkActive="active-link"
                      >Teachers (Tutors)
                      </a
                    >
                  </li>
                  <!-- <li><a href="javascript:void(0)" routerLink="/admin/new-teacher"
                             routerLinkActive="active-link">New Teacher Request</a></li> -->
                </ul>
              </div>
            </li>

            <li class="sidebar-dropdown">
                <a href="javascript:void(0)"><i class="fas fa-money-bill-alt"></i><span>Class Manager</span></a>
                <div class="sidebar-submenu">
                    <ul>
                      <!-- <li><a href="javascript:void(0)" routerLink="/course" routerLinkActive="active-link">Courses </a>
                      </li> -->
                      <!-- <li><a href="javascript:void(0)" routerLink="/classes" routerLinkActive="active-link">Classes</a></li> -->
                      <li >
                        <a
                          href="javascript:void(0)"
                          routerLink="/category"
                          routerLinkActive="active-link"
                          >Subjects</a>
                      </li>
                      <!-- <li><a href="javascript:void(0)" routerLink="/quiz" routerLinkActive="active-link">Quiz</a></li>  -->
                      <li><a href="javascript:void(0)" routerLink="/project" routerLinkActive="active-link">Quiz Papers</a></li>
                      <li >
                        <a href="javascript:void(0)" routerLink="/classrequest/demo" routerLinkActive="active-link">Demo Classes</a>
                    </li>
                    <li >
                        <a href="javascript:void(0)" routerLink="/classrequest/Booked_class" routerLinkActive="active-link">Booked Classes</a>
                    </li>
                      <!-- <li><a href="javascript:void(0)" routerLink="/class-schedule" routerLinkActive="active-link"> Class
                          Schedule</a></li> -->
                      <!-- <li><a href="javascript:void(0)" routerLink="/quiz" routerLinkActive="active-link">Quiz</a></li> -->
                    </ul>
                </div>
              </li>
              
              <!-- <li class="sidebar">
                  <a href="javascript:void(0)" routerLink="/shared/subscription-list" routerLinkActive="active-link"><i class="fas fa-list"></i><span>Booked Courses</span></a>
              </li> -->
              <!-- <li class="sidebar">
                <a href="javascript:void(0)" routerLink="/payout" routerLinkActive="active-link"><i
                   class="fas fa-list"></i><span>Teachers Payments</span></a>
             </li> -->
              <li class="sidebar">
                <a href="javascript:void(0)" routerLink="/admin/my-earnings" routerLinkActive="active-link"><i
                   class="fas fa-list"></i><span>Tutor Earnings</span></a>
             </li>
              <li class="sidebar">
                <a href="javascript:void(0)" routerLink="/admin/withdraw_section" routerLinkActive="active-link"><i
                   class="fas fa-list"></i><span>Tutor Withdraw Balance Request</span></a>
             </li>
              <li class="sidebar">
                <a href="javascript:void(0)" routerLink="/admin/my-transactions" routerLinkActive="active-link"><i
                   class="fas fa-list"></i><span>Teacher Payments</span></a>
             </li>
            <!-- <li class="sidebar">
                    <a href="javascript:void(0)" routerLink="/community/post" routerLinkActive="active-link"><i
                       class="fas fa-brain"></i><span>Community Post</span></a>
                 </li> -->
            
            <!-- <li class="sidebar-dropdown">
                    <a href="javascript:void(0)"><i class="fas fa-bell"></i><span>Student Subscription</span></a>
                    <div class="sidebar-submenu">
                       <ul>
                          <li><a href="javascript:void(0)" routerLink="/shared/subscription-list"
                             routerLinkActive="active-link">Subscription List</a></li>
                          <li><a href="javascript:void(0)" routerLink="/shared/course-assign-to-student"
                             routerLinkActive="active-link">Add Subscription</a></li>
                       </ul>
                    </div>
                 </li> -->
            <!-- <li class="sidebar-dropdown">
              <a
                href="javascript:void(0)"
                routerLink="/shared/role"
                routerLinkActive="active-link"
                ><i class="fas fa-user-tag"></i><span>Role</span></a
              >
            </li> -->
            <li class="sidebar">
              <a
                href="javascript:void(0)"
                routerLink="/shared/age-group"
                routerLinkActive="active-link"
                ><i class="fas fa-child"></i><span>Level/Grade</span></a
              >
            </li>
            <li class="sidebar">
              <a
                href="javascript:void(0)"
                routerLinkActive="active-link"
                routerLink="/enquiry-list"

                ><i class="fas fa-question-circle"></i><span>In-person class Enquiry</span></a
              >
            </li>
            <li class="sidebar">
              <a
                href="javascript:void(0)"
                routerLinkActive="active-link"
                routerLink="/contact_enquiry"
                ><i class="fas fa-question-circle"></i><span>Contact Enquiry</span></a
              >
            </li>
            <!-- <li class="sidebar">
              <a
                href="javascript:void(0)"
                routerLink="/support"
                routerLinkActive="active-link"
                ><i class="fas fa-question-circle"></i><span>Support</span></a
              >
            </li> -->
            <li class="sidebar">
              <a
                href="javascript:void(0)"
                routerLink="/newsletters"
                routerLinkActive="active-link"
                ><i class="fas fa-question-circle"></i><span>Newsletter Subscribers</span></a
              >
            </li>
            <!-- <li class="sidebar">
                    <a href="javascript:void(0)" routerLink="/course" routerLinkActive="active-link"><i class="fas fa-book-open"></i><span>Courses</span></a>
                    
                    </li>
                    <li class="sidebar">
                    <a href="javascript:void(0)" routerLink="/certificate" routerLinkActive="active-link"><i class="fas fa-tachometer-alt"></i><span>Certificate </span></a>
                    
                    </li>
                    <li class="sidebar">
                    <a href="javascript:void(0)" routerLink="/classes" routerLinkActive="active-link"><i class="fas fa-tachometer-alt"></i><span>Class</span></a>
                    
                    </li>
                    <li class="sidebar">
                    <a href="javascript:void(0)" routerLink="/class-schedule" routerLinkActive="active-link"><i class="fas fa-clock"></i><span>Class Schedule</span></a>
                    
                    </li> -->
            <!-- <li class="sidebar">
                    <a href="javascript:void(0)" routerLink="/certificate" routerLinkActive="active-link"><i
                       class="fas fa-file-signature"></i><span> Certificate</span></a>
                 </li>
                 
                 <li class="sidebar">
                    <a href="javascript:void(0)" routerLink="/notifcation" routerLinkActive="active-link"><i
                       class="fas fa-bell"></i><span>Notification</span></a>
                 </li>
                 
                 <li class="sidebar">
                    <a href="javascript:void(0)" routerLink="/enquiry-list" routerLinkActive="active-link"><i class="fas fa-question-circle"></i><span>Enquiry</span></a>
                 </li> -->
            
            <li class="sidebar-dropdown">
              <a href="javascript:void(0)"
                ><i class="fas fa-headset"></i><span>Site Management</span></a
              >
              <div class="sidebar-submenu">
                <ul>
                  <li >
                    <a href="javascript:void(0)" routerLink="/banner" routerLinkActive="active-link">Banner</a>
                  </li>
                  
                  <li >
                    <a href="javascript:void(0)" routerLink="/blogs" routerLinkActive="active-link">Blogs</a>
                  </li>
                  
                  <!-- <li>
                    <a
                      href="javascript:void(0)"
                      routerLink="/support"
                      routerLinkActive="active-link"
                      >Support</a
                    >
                  </li> -->
                  <li>
                    <a
                      href="javascript:void(0)"
                      routerLink="/block-content"
                      routerLinkActive="active-link"
                      >Content Blocks</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      routerLink="/cms"
                      routerLinkActive="active-link"
                      >CMS</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      routerLink="/faq/teacher"
                      routerLinkActive="active-link"
                      >Support / Teachers FAQ
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      routerLink="/faq/student"
                      routerLinkActive="active-link"
                      >Support / Student FAQ
                    </a>
                  </li>
                  <!-- <li>
                    <a
                      href="javascript:void(0)"
                      routerLink="/faq/school"
                      routerLinkActive="active-link"
                      >School FAQ
                    </a>
                  </li> -->
                  <!-- <li><a href="javascript:void(0)" routerLink="/faq" routerLinkActive="active-link">Contact Query</a>
                             </li> -->
                </ul>
              </div>
            </li>
            <li class="sidebar">
               <a
                 href="javascript:void(0)"
                 routerLink="/testimonials"
                 routerLinkActive="active-link"
                 ><i class="fas fa-bread-slice"></i><span>Raving Reviews</span></a
               >
             </li>
            <li class="sidebar-dropdown">
              <a href="javascript:void(0)"
                ><i class="fas fa-cog"></i><span>Settings</span></a
              >
              <div class="sidebar-submenu">
                <ul>
                  <!-- <li>
                    <a
                      href="javascript:void(0)"
                      routerLink="/shared/demo-class-setting"
                      routerLinkActive="active-link"
                      >Demo Class Setting</a
                    >
                  </li>
                  
                  <li>
                    <a
                      href="javascript:void(0)"
                      routerLink="/shared/point-setting"
                      routerLinkActive="active-link"
                      >Points Setting</a
                    >
                  </li> -->
                  <!-- <li>
                    <a
                      href="javascript:void(0)"
                      routerLink="/shared/teacher-class-setting"
                      routerLinkActive="active-link"
                      >Teacher Class Setting</a
                    >
                  </li> -->
                  <li>
                    <a
                      href="javascript:void(0)"
                      routerLink="/settings"
                      routerLinkActive="active-link"
                      >Site Settings</a
                    >
                    
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      routerLink="/shared/change-password"
                      routerLinkActive="active-link"
                      >Change Password</a
                    >
                  </li>
                </ul>
              </div>
            </li>
            
            <!-- Coupon -->
            <!-- <li class="sidebar">
              <a
                href="javascript:void(0)"
                routerLink="/coupon"
                routerLinkActive="active-link"
                ><i class="fas fa-bread-slice"></i><span>Coupon</span></a
              >
            </li>
            <li class="sidebar">
              <a
                href="javascript:void(0)"
                routerLink="/school"
                routerLinkActive="active-link"
                ><i class="fas fa-bread-slice"></i><span>School</span></a
              >
            </li> -->
            <!-- <li class="sidebar-dropdown">
              <a href="javascript:void(0)"
                ><i class="fas fa-list"></i><span>Payouts</span></a
              >
              <div class="sidebar-submenu">
                <ul>
                  <li>
                    <a
                      href="javascript:void(0)"
                      routerLink="/school-payout/pending-payouts"
                      routerLinkActive="active-link"
                      >Pending Payouts
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      routerLink="/school-payout/all-payouts"
                      routerLinkActive="active-link"
                      >All Payouts
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      routerLink="/school-payout/generated-payouts"
                      routerLinkActive="active-link"
                      >Generated Payouts
                    </a>
                  </li>
                </ul>
              </div>
            </li> -->
            <!-- <li class="sidebar-dropdown">
              <a href="javascript:void(0)"
                ><i class="fas fa-list"></i><span>Refer and Earn</span></a
              >
              <div class="sidebar-submenu">
                <ul>
                  <li>
                    <a
                      href="javascript:void(0)"
                      routerLink="/invite-payouts/pending-payouts"
                      routerLinkActive="active-link"
                      >Pending Payouts
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      routerLink="/invite-payouts/all-payouts"
                      routerLinkActive="active-link"
                      >All Payouts
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      routerLink="/invite-payouts/generated-payouts"
                      routerLinkActive="active-link"
                      >Generated Payouts
                    </a>
                  </li>
                </ul>
              </div>
            </li> -->
            <!-- <li class="sidebar">
              <a
                href="javascript:void(0)"
                routerLink="/class-template"
                routerLinkActive="active-link"
                ><i class="fas fa-bread-slice"></i
                ><span>Class Duration Template</span></a
              >
            </li> -->
            <!-- <li class="sidebar">
              <a
                href="javascript:void(0)"
                routerLink="/live-classes"
                routerLinkActive="active-link"
                ><i class="fas fa-bread-slice"></i><span>Live Classes</span></a
              >
            </li> -->
            <!-- <li class="sidebar-dropdown">
              <a href="javascript:void(0)"
                ><i class="fas fa-bell"></i><span>Awards</span></a
              >
              <div class="sidebar-submenu">
                <ul>
                  <li>
                    <a
                      href="javascript:void(0)"
                      routerLink="/awards/student"
                      routerLinkActive="active-link"
                      >Student Awards</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      routerLink="/awards/teacher"
                      routerLinkActive="active-link"
                      >Teacher Awards</a
                    >
                  </li>
                </ul>
              </div>
            </li> -->
            <!-- <li class="sidebar">
              <a
                href="javascript:void(0)"
                routerLink="/school-report"
                routerLinkActive="active-link"
                ><i class="fas fa-bread-slice"></i><span>School Report</span></a
              >
            </li> -->
            <!-- <li class="header-menu"><span>Learning Pie</span></li>
            <li class="sidebar">
              <a
                href="javascript:void(0)"
                routerLink="/l-pie/demo"
                routerLinkActive="active-link"
                ><i class="fas fa-brain"></i
                ><span>Learning Pie Enquiries</span></a
              >
            </li>
            <li class="sidebar">
              <a
                href="javascript:void(0)"
                routerLink="/l-pie/age-group"
                routerLinkActive="active-link"
                ><i class="fas fa-child"></i><span>Age Group</span></a
              >
            </li>
            <li class="sidebar">
              <a
                href="javascript:void(0)"
                routerLink="/l-pie/courses-list"
                routerLinkActive="active-link"
                ><i class="fas fa-child"></i><span>Courses</span></a
              >
            </li>
            <li class="sidebar">
              <a
                href="javascript:void(0)"
                routerLink="/l-pie/quiz"
                routerLinkActive="active-link"
                ><i class="fas fa-child"></i><span>FeedBack</span></a
              >
            </li>
            <li class="sidebar">
              <a
                href="javascript:void(0)"
                routerLink="/l-pie/inquiresList"
                routerLinkActive="active-link"
                ><i class="fas fa-child"></i><span>Enquiry</span></a
              >
            </li>
            <li class="sidebar">
              <a
                href="javascript:void(0)"
                routerLink="/l-pie/community-list"
                routerLinkActive="active-link"
                ><i class="fas fa-child"></i><span>community</span></a
              >
            </li>
            <li class="sidebar">
              <a
                href="javascript:void(0)"
                routerLink="/l-pie/classes-list"
                routerLinkActive="active-link"
                ><i class="fas fa-child"></i><span>Classes</span></a
              >
            </li>
            <li class="sidebar">
              <a
                href="javascript:void(0)"
                routerLink="/l-pie/category-list"
                routerLinkActive="active-link"
                ><i class="fas fa-child"></i><span>Category</span></a
              >
            </li>
            <li class="sidebar">
              <a
                href="javascript:void(0)"
                routerLink="/assessment"
                routerLinkActive="active-link"
                ><i class="fas fa-bread-slice"></i><span>Assessment</span></a
              >
            </li>
            <li class="sidebar">
              <a
                href="javascript:void(0)"
                routerLink="/story-time"
                routerLinkActive="active-link"
                ><i class="fas fa-clipboard-list"></i><span>story time</span></a
              >
            </li>
            <li class="sidebar-dropdown">
              <a href="javascript:void(0)"
                ><i class="fas fa-bell"></i><span>Student Subscription</span></a
              >
              <div class="sidebar-submenu">
                <ul>
                  <li>
                    <a
                      href="javascript:void(0)"
                      routerLink="/l-pie/subscription-list"
                      routerLinkActive="active-link"
                      >Subscription List</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      routerLink="/l-pie/course-assign-to-student"
                      routerLinkActive="active-link"
                      >Add Subscription</a
                    >
                  </li>
                </ul>
              </div>
            </li> -->
            <li class="sidebar">
              <a 
                href="javascript:void(0)"
                data-toggle="modal" data-target="#lot"
                (click)="onLogout()"
                routerLinkActive="active-link"
                ><i class="fas fa-sign-out-alt"></i><span>Log Out</span></a
              >
            </li>
          </ul>
        </div>
        <!-- sidebar-menu  -->
        <!-- sidebar-menu  -->
      </div>
      <!-- sidebar-content  -->
      <!-- <div class="sidebar-footer">
           <a href="javascript:void(0)"><i class="fa fa-bell"></i><span
               class="label label-warning notification">3</span></a>
           <a href="javascript:void(0)"><i class="fa fa-envelope"></i><span
               class="label label-success notification">7</span></a>
           <a href="javascript:void(0)"><i class="fa fa-gear"></i></a>
           <a href="javascript:void(0)" (click)="onLogout()"><i class="fa fa-power-off"></i></a>
           </div> -->
    </nav>
    <!-- sidebar-wrapper  -->
    <main class="page-content">
      <div class="container-fluid">
        <div class="inner-content">
          <router-outlet></router-outlet>
        </div>
      </div>
    </main>
    <!-- page-content" -->
  </div>
  <!-- page-wrapper -->
</div>
<div class="modal fade show pp" id="lot" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered logmod" role="document">
      <div class="modal-content">
          <div class="modal-header">
              <h1 class="modal-title">Log Out</h1>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">
              <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 pr-0">
                      <!-- start -->
                      <div class="log_box pt-0">
                          <div class="login_form">
                              <p class="mt10 ac">
                                  <label>Are you sure, you want to log out?</label>
                                  <div class="row">
                                      <div class="col-md-6 ac"><a href="javascript:void(0);"  class="login_btn" data-dismiss="modal">Cancel</a></div>
                                      <div class="col-md-6 ac"> <a href="javascript:void(0);" class="login_btn" (click)="onLogout()">Yes</a></div>
                                  </div>
                          </div>
                      </div>
                      <!-- end -->
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

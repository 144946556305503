import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LayoutComponent } from './layout/layout.component';
import { EnquirylistComponent } from './enquiries_list/enquiries_list.component';
import { ContactEnquiryComponent } from './contact_enquiry/contact_enquiry.component';


const routes: Routes = [
  { path: '', loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule) },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
      { path: 'shared', loadChildren: () => import('./shared/shared.module').then(m => m.SharedModule) },
      { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
      { path: 'course', loadChildren: () => import('./course/course.module').then(m => m.CourseModule) },
      { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
      { path: 'faq', loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule) },
      { path: 'payout', loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule) },
      { path: 'notifcation', loadChildren: () => import('./notifcation/notifcation.module').then(m => m.NotifcationModule) },
      { path: 'support', loadChildren: () => import('./support/support.module').then(m => m.SupportModule) },
      { path: 'newsletters', loadChildren: () => import('./newsletters/newsletters.module').then(m => m.NewslettersModule) },
      { path: 'quiz', loadChildren: () => import('./quiz/quiz.module').then(m => m.QuizModule) },
      { path: 'class-schedule', loadChildren: () => import('./class-schedule/class-schedule.module').then(m => m.ClassScheduleModule) },
      { path: 'classes', loadChildren: () => import('./classes/classes.module').then(m => m.ClassesModule) },
      { path: 'banner', loadChildren: () => import('./banner/banner.module').then(m => m.BannerModule) },
      { path: 'cms', loadChildren: () => import('./cms/cms.module').then(m => m.CmsModule) },
      { path: 'certificate', loadChildren: () => import('./certificate/certificate.module').then(m => m.CertificateModule) },
      { path: 'project', loadChildren: () => import('./project/project.module').then(m => m.ProjectModule) },
      { path: 'classrequest', loadChildren: () => import('./classrequest/classrequest.module').then(m => m.ClassrequestModule) },
      { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule) },
      { path: 'category', loadChildren: () => import('./category/category.module').then(m => m.CategoryModule) },
      { path: 'community', loadChildren: () => import('./community/community.module').then(m => m.CommunityModule) },
      { path: 'blogs', loadChildren: () => import('./blogs/blogs.module').then(m => m.BlogsModule) },
      { path: 'testimonials', loadChildren: () => import('./testimonials/testimonials.module').then(m => m.TestimonialsModule) },
      {path:'enquiry-list',component:EnquirylistComponent},
      {path:'contact_enquiry',component:ContactEnquiryComponent},
      { path: 'story-time', loadChildren: () => import('./story-time/story-time.module').then(m => m.StoryTimeModule) },
      { path: 'l-pie', loadChildren: () => import('./learning-pie/learning-pie.module').then(m => m.LearningPieModule) },

      { path: 'coupon', loadChildren: () => import('./coupon/coupon.module').then(m => m.CouponModule) },
      { path: 'school', loadChildren: () => import('./school/school.module').then(m => m.SchoolModule) },
      { path: 'assessment', loadChildren: () => import('./assessments/assessments.module').then(m => m.AssessmentModule) },
      { path: 'school-payout', loadChildren: () => import('./payouts/payouts.module').then(m => m.PayoutsModule) },
      { path: 'invite-payouts', loadChildren: () => import('./invite-payouts/invite-payouts.module').then(m => m.InvitePayoutsModule) },
      { path: '', loadChildren: () => import('./class-template/class-template.module').then(m => m.ClassTemplateModule) },
      { path: 'live-classes', loadChildren: () => import('./live-classes/live-classes.module').then(m => m.LiveClassModule) },
      { path: 'awards', loadChildren: () => import('./awards/awards.module').then(m => m.AwardsModule) },
      { path: 'block-content', loadChildren: () => import('./block_content/block_content.module').then(m => m.BlockContentModule) },
      { path: '', loadChildren: () => import('./module/Spage.module').then(m => m.SPageModule) },
      { path: 'settings', loadChildren: () => import('./site_settings/settings.module').then(m => m.SettingsModule) },
    ]
  },

  

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  // imports: [RouterModule.forRoot(routes,{enableTracing: true, scrollPositionRestoration: 'enabled'})],

  exports: [RouterModule]
})
export class AppRoutingModule { }
